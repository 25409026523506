<template>
  <span
    ref="componentWrapper"
    class="subject-item group inline-flex hover:z-50 relative group no-print"
    :class="paddingRight ? 'pr-3' : ''"
  >
    <span class="background bg-white shadow z-30 hidden md:block" />
    <div
      class="fixed bg-black bg-opacity-0 w-full h-full top-0 left-0 pointer-events-none transition-all duration-200 group-hover:bg-opacity-10 z-20"
    />
    <span class="default article-head-m z-30 inline-flex items-center">
      <img
        v-if="person.image"
        class="w-7 h-7 md:w-12 md:h-12 inline-block overflow-hidden rounded-full bg-cover mr-2 shrink-0 grow-0"
        :src="getPersonImageString(person)"
      />
      <span
        class="opacity-35 whitespace-nowrap"
        :class="
          isMm
            ? 'font-normal'
            : layout === 'big'
            ? 'font-medium'
            : 'list-title-xs'
        "
      >
        {{ person.name }}
      </span>
    </span>
    <div class="extended w-full body-xs z-30 hidden md:block">
      <div ref="content">
        <div class="list-title-xxxs !font-medium max-two-lines">
          {{ person.title }}
        </div>
        <div
          v-if="isPersonDTO(person, type) && person.education"
          class="body-xs text-gray-500 mt-1 max-two-lines"
          v-html="person.education"
        />
        <div
          v-else-if="isWriterDTO(person, type) && person.description"
          class="body-xs text-gray-500 mt-1 max-two-lines"
          v-html="person.description"
        />
        <div class="flex items-center justify-between w-full mt-5">
          <div
            v-if="
              isPersonDTO(person, type) &&
              person.birthday &&
              person.birthday != '1900-01-01T00:00:00'
            "
            class="flex items-center"
          >
            <img
              src="~/assets/icons/birthday.svg?url"
              class="inline-block w-3 h-3 mr-1 opacity-35"
            />
            <span class="inline-block list-label opacity-50">{{
              birthDayString
            }}</span>
          </div>
          <NuxtLink
            v-if="person.urlKey"
            :to="
              isWriterDTO(person, type)
                ? {
                    name: 'writer',
                    params: {
                      writer: person.urlKey,
                    },
                  }
                : {
                    name: 'person',
                    params: {
                      person: person.urlKey,
                    },
                  }
            "
          >
            <CommonButton size="medium">
              {{ $t('ViewCard') }}
            </CommonButton>
          </NuxtLink>
        </div>
      </div>
    </div>
    <div
      class="popup fixed transform duration-300 ease-out w-full body-xs z-30 bottom-0 left-0 bg-white rounded-t-xl shadow pb-20 md:hidden group-hover:translate-y-0 translate-y-full"
    >
      <div class="container pt-5">
        <div class="flex justify-between">
          <img
            v-if="person.image"
            class="w-16 h-16 mb-3 inline-block overflow-hidden rounded-full bg-cover mr-2 shrink-0 grow-0"
            :src="getPersonImageString(person)"
          />
          <NuxtLink
            v-if="person.urlKey"
            :to="`/${
              isWriterDTO(person, type)
                ? $t('Routes.Writer')
                : $t('Routes.Person')
            }/${person.urlKey}`"
          >
            <CommonButton size="medium">
              {{ $t('ViewCard') }}
            </CommonButton>
          </NuxtLink>
        </div>
        <div class="headline-s text-red mb-2">
          {{ person.name }}
        </div>
        <div class="font-bold max-two-lines">
          {{ person.title }}
        </div>
        <div
          v-if="isPersonDTO(person, type) && person.education"
          class="text-gray-600 mt-2 max-two-lines"
          v-html="person.education"
        />
        <div
          v-else-if="isWriterDTO(person, type) && person.description"
          class="text-gray-600 mt-2 max-two-lines"
          v-html="person.description"
        />
        <div class="flex items-center justify-between w-full mt-5">
          <div
            v-if="
              isPersonDTO(person, type) &&
              person.birthday &&
              person.birthday != '1900-01-01T00:00:00'
            "
            class="flex items-center"
          >
            <img
              src="~/assets/icons/birthday.svg?url"
              class="inline-block w-3 h-3 mr-1 opacity-35"
            />
            <span class="inline-block body-xs opacity-50">{{
              birthDayString
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </span>
</template>

<script setup lang="ts">
import type {
  ArticleWriterDTO,
  PersonArticleDTO,
  Person,
} from '~/typesAuto/apicore/v1'

const { isMm } = useBrand()
const config = useRuntimeConfig()

const content = ref<HTMLDivElement>()
const componentWrapper = ref<HTMLSpanElement>()

function isPersonDTO(
  person: PersonArticleDTO | ArticleWriterDTO | Person,
  type: string
): person is PersonArticleDTO {
  return type === 'person'
}

function isWriterDTO(
  person: PersonArticleDTO | ArticleWriterDTO | Person,
  type: string
): person is ArticleWriterDTO {
  return type === 'writer'
}

const getPersonImageString = (
  person: PersonArticleDTO | ArticleWriterDTO | Person
) => {
  if (!person.image) {
    return ''
  }

  if (isWriterDTO(person, props.type)) {
    return `${
      config.public.site.legacydomain
    }/images/writers/${appendToFilename(person.image, '-2-48')}`
  }

  if (person.masterId && person.masterId !== 0) {
    return `https://legacy.altinget.dk/images/person/${person.masterId}/${person.image}`
  }

  return `${config.public.site.legacydomain}/images/person/${person.id}/${person.image}`
}

const props = withDefaults(
  defineProps<{
    number?: number
    person?: PersonArticleDTO | ArticleWriterDTO | Person
    type?: string
    paddingRight?: boolean
    layout?: string
  }>(),
  {
    number: 1,
    person: () => {
      return {} as PersonArticleDTO
    },
    type: 'writer',
    paddingRight: false,
    layout: 'big',
  }
)
const expandedHeight = ref('0px')

const birthDayString = computed(() => {
  if (isPersonDTO(props.person, props.type) && props.person?.birthday) {
    return convertBirthdayDate(props.person.birthday)
  }
  return ''
})

onMounted(() => {
  // It's not possible to animate to auto height with css. Therefore we need need to calculate the height of the content
  // We then use this height down in the CSS with v-bind to set the height of the background.
  // We can't use tailwind exclusively because it doesn't have support for stuff like
  // group-hover:h-[JAVASCRIPT-VALUE]
  expandedHeight.value = `${
    (content.value?.offsetHeight ?? 0) +
    (componentWrapper.value?.offsetHeight ?? 0) +
    40 // Don't ask why 40. Was 40 in the old component
  }px`
})
</script>

<style lang="scss" scoped>
.subject-item:hover .background {
  height: v-bind(expandedHeight);
}
.max-two-lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.background {
  position: absolute;
  top: -15px;
  left: -15px;
  width: calc(100% + 30px);
  height: calc(100% + 30px);
  border-radius: 12px;
  transition: opacity 200ms, height 400ms;
  opacity: 0;
}
.subject-item {
  position: relative;
  &:hover {
    .background {
      opacity: 1;
    }
    .extended {
      pointer-events: all;
      opacity: 1;
      transition-delay: 300ms;
    }
  }
}
.extended {
  position: absolute;
  top: calc(100% + 10px);
  bottom: 0;
  pointer-events: none;
  opacity: 0;
  transition: opacity 200ms;

  p {
    margin-bottom: 10px;
  }
}
</style>
